import React, {useEffect, useState} from "react";
import { Routes, Route, Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";

import {ReactComponent as Loader} from "./assets/loader.svg";

import { SelectedItemProvider } from './SelectedItemContext';

import HeroMaskLogo from "./assets/hero_mask_logo_onwhite.webp";

import styled from 'styled-components';

import { 
    InvitationType, 
    MessageGroupType, 
    ProfileType, 
    QueuedItemType, 
    SpaceStationType, 
    SpaceType, 
    TaskType,
    UserFollowingType
} from "./Types/HeroTypes";
import HStack from "./components/utils/HStack";
import HSpacer from "./components/utils/HSpacer";
import Colors from "./services/Colors";
const StoreFront = React.lazy(() => import("./views/Marketplace/StoreFront"));
const InviteCode = React.lazy(() => import("./views/Marketplace/InviteCode"));
// const ReplyThreadPage = React.lazy(() => import("./views/Public/ReplyThreadPage"));

// const HoverDropdown = React.lazy(() => import("./views/Extracts/HoverDropdown"));

const WhatIsAHeroPage = React.lazy(() => import("./views/Articles/WhatIsAHeroPage"));

const LinkInBioTwitter = React.lazy(() => import("./views/Articles/LinkInBioTwitter"));
const LinkInBioInstagram = React.lazy(() => import("./views/Articles/LinkInBioInstagram"));
const LinkInBioTikTok = React.lazy(() => import("./views/Articles/LinkInBioTikTok"));

const Header = React.lazy(() => import("./views/Header"));

const Home = React.lazy(() => import("./views/Home"));

// const Oracle = React.lazy(() => import("./views/Oracle"));

const DocsArticle = React.lazy(() => import("./views/ArticleOS/DocsArticle"));
const NewsArticle = React.lazy(() => import("./views/ArticleOS/NewsArticle"));

const ArticleWidget = React.lazy(() => import("./views/ArticleOS/ArticleWidget"));
// const PromptsWidget = React.lazy(() => import("./views/ArticleOS/PromptsWidget"));
// const PromptsFeed = React.lazy(() => import("./views/ArticleOS/PromptsFeed"));

const NewsFeed = React.lazy(() => import("./views/ArticleOS/NewsFeed"));
const ArticleFeed = React.lazy(() => import("./views/ArticleOS/ArticleFeed"));
// const Blog = React.lazy(() => import("./views/ArticleOS/Blog"));

// const FrontPage = React.lazy(() => import("./views/FrontPage"));
const Explore = React.lazy(() => import("./views/Explore"));
const Download = React.lazy(() => import("./views/Download"));

const ShareAListOfLinks = React.lazy(() => import("./views/Articles/ShareAListOfLinks"));
const ShareMultipleLinks = React.lazy(() => import("./views/Articles/ShareMultipleLinks"));
const ShareMultipleLinksOnSlack = React.lazy(() => import("./views/Articles/ShareMultipleLinksOnSlack"));

const NewsAbout = React.lazy(() => import("./views/Articles/NewsAbout"));

const AdventureLists = React.lazy(() => import("./views/Articles/AdventureLists"));

const ToastContainer = React.lazy(async () => {
    const { ToastContainer } = await import('react-toastify');
    return { default: ToastContainer };
});

const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const TermsAndConditions = React.lazy(() => import("./views/TermsAndConditions"));

const CreatAccount = React.lazy(() => import("./views/Auth/CreateAccount"));
const ForgotPassword = React.lazy(() => import("./views/Auth/ForgotPassword"));
const SignIn = React.lazy(() => import("./views/Auth/SignIn"));

const SpaceEditor = React.lazy(() => import("./views/App/SpaceEditor"));

// const SpaceV2 = React.lazy(() => import("./views/Public/SpaceV2"));
const SpaceV3 = React.lazy(() => import("./views/Public/SpaceV3"));

// const Try = React.lazy(() => import("./views/Demo/Try"));

const User = React.lazy(() => import("./views/Public/User"));

const SearchUser = React.lazy(() => import("./views/Public/SearchUser"));

const AuthSignIn = React.lazy(() => import("./views/Auth/AuthSignIn"));

const NothingPlaceholder = React.lazy(() => import("./views/NothingPlaceholder"));

const AppContainer = styled.div ``;

function App() {
    const {pathname} = useLocation();

    const [search] = useSearchParams();

    const [pings, setPings] = React.useState(0);

    const isInIFrame = search.get('iframe') === "true" && search.get('iframe') !== null && search.get('iframe') !== undefined;

    const fromExternal = search.get('ext') === "1" && search.get('ext') !== null && search.get('ext') !== undefined;
    const extLink = search.get('link');
    const extTitle = search.get('title');
    
    const [loadHeader, setLoadHeader] = useState<boolean>(pathname.includes("dashboard"));

    const [profile, setProfile] = useState<ProfileType | null>(null);

    const [messageGroups, setMessageGroups] = useState<MessageGroupType[]>([]);
    const [friends, setFriends] = useState<ProfileType[]>([]);

    const [savedSpaces, setSavedSpaces] = useState<SpaceType[]>([]);
    const [savedSpacesProfiles, setSavedSpacesProfiles] = useState<ProfileType[]>([]);
    const [spaces, setSpaces] = useState<SpaceType[]>([]);

    const [usersFollowing, setUsersFollowing] = useState<UserFollowingType[]>([]);

    const [station, setStation] = useState<SpaceStationType[]>([]);
    const [queue, setQueue] = useState<QueuedItemType[]>([]);

    const [isCreateSpaceOpen, setIsCreateSpaceOpen] = useState(false);
    const [isNewSpaceAppWorkflow, setIsNewSpaceAppWorkflow] = useState(false);

    const [invitations, setInvitations] = useState<InvitationType[]>([]);

    // To add an item to your own space
    const [selectedItem, setSelectedItem] = useState<TaskType | null>(null);

    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [userLoaded, setUserLoaded] = useState(false);

    const redirectForAIDisclaimer = "/samir/sam-chahine-s-blog/i/why-hero-will-no-longer-CAcJTOfzl73n6rnwZdjT"

    // const cashInTicket = async (pathname: string) => {
    //     try {
    //         await fetch(
    //             `https://us-central1-focushero-1650416072840.cloudfunctions.net/redeemer/${encodeURIComponent(pathname)}`
    //         );

    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
              const images = Array.from(document.querySelectorAll('img[loading="lazy"]'));
              images.forEach((img) => {
                const rect = img.getBoundingClientRect();
                if (rect.top >= 0 && rect.top < window.innerHeight) {
                  img.setAttribute("loading", "eager");
                }
              });
            }
          }
        });
    
        observer.observe(document.body, {
          attributes: false,
          childList: true,
          subtree: true,
        });
    
        return () => observer.disconnect();
      }, []);

      useEffect(() => {
        const host = window.location.hostname;
        const appStoreLink = "https://apps.apple.com/au/app/hero/id1632645305";

        if (host === "get.hero.page") {
            // Redirecting to the App Store
            window.location.href = appStoreLink;
        }
      }, []); // The empty array makes this effect only run on mount
    
    

    useEffect(() => {
        window.scrollTo(0, 0);

        // List of paths where the Google Ads script should be included
        const adScriptPaths = ['/pfp', '/coloring', '/wallpapers', '/search', '/blog', '/ai-prompts', '/samir', '/templates'];
        const googleAdsScriptId = 'google-ads-script';

        const addGoogleAdsScript = () => {
            if (!document.getElementById(googleAdsScriptId)) {
                const googleAdsScript = document.createElement('script');
                googleAdsScript.id = googleAdsScriptId;
                googleAdsScript.async = true;
                googleAdsScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6880786555479410";
                googleAdsScript.crossOrigin = "anonymous";
                document.head.appendChild(googleAdsScript);
            }
        };

        const removeGoogleAdsScript = () => {
            // console.log("🚫 no dont show ads on " + pathname)
            const existingScript = document.getElementById(googleAdsScriptId);
            if (existingScript) {
                // console.log("Removing ad...")
                document.head.removeChild(existingScript);
            }
        };

        if (adScriptPaths.some(path => pathname.includes(path))) {
            addGoogleAdsScript();
        } else {
            removeGoogleAdsScript();
        }

        // Cleanup function
        return () => {
            removeGoogleAdsScript();
        };

    }, [pathname]);

    useEffect(() => {
        let fontTimer = setTimeout(() => {
            let robotoFont = document.createElement('link');

            robotoFont.rel = "stylesheet";
            robotoFont.as = "style";
            robotoFont.href = "https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=optional";

            document.head.appendChild(robotoFont);
        }, 7000);

        let recaptchaTimer = setTimeout(() => {
            let recaptchaScript = document.createElement('script');

            recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=6LfpY9UhAAAAAHuraKEGkgBJGsLDNxyn6Ae3gqQU`;
    
            recaptchaScript.defer = true;
    
            document.body.appendChild(recaptchaScript);

            let GTagManager = document.createElement('script');

            GTagManager.src = "https://www.googletagmanager.com/gtag/js?l=dataLayer&id=G-YF8G7ZKLDH";
            GTagManager.defer = true;

            document.head.appendChild(GTagManager);

        }, 7000);


        let headerTimer = setTimeout(() => {
            setLoadHeader(true)
        }, fromExternal ? 0 : 0);

        let userLoadedTimer = setTimeout(() => {
            setUserLoaded(true)
        }, fromExternal ? 0 : 0);

        return () => {
            clearTimeout(recaptchaTimer);
            clearTimeout(fontTimer);
            clearTimeout(headerTimer);
            clearTimeout(userLoadedTimer)
        };
    }, []);

    return (
        <SelectedItemProvider
            profile={profile}
            spaces={spaces}
            station={station}
            setProfile={setProfile}
            setIsCreateSpaceOpen={setIsCreateSpaceOpen}
            StoreFrontLeftColRef={null}
        >
            <AppContainer>
                {
                    !isInIFrame ?
                    loadHeader ?
                    <React.Suspense fallback={<PlaceholderHeader />}>
                        <Header 
                            usersFollowing={usersFollowing}
                            setUsersFollowing={setUsersFollowing}
                            setPings={setPings}
                            pings={pings}
                            profile={profile} 
                            invitations={invitations} 
                            station={station} 
                            messageGroups={messageGroups}
                            setStation={setStation} 
                            friends={friends} 
                            savedSpaces={savedSpaces} 
                            spaces={spaces} 
                            setProfile={setProfile}
                            setMessageGroups={setMessageGroups}
                            setFriends={setFriends}
                            setSavedSpaces={setSavedSpaces}
                            setSavedSpacesProfiles={setSavedSpacesProfiles}
                            setSpaces={setSpaces}
                            setQueue={setQueue}
                            setInvitations={setInvitations}
                            fromExternal={fromExternal}
                            extLink={extLink}
                            extTitle={extTitle}
                            setIsUserSignedIn={setIsUserSignedIn}
                            isCreateSpaceOpen={isCreateSpaceOpen}
                            setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            isNewSpaceAppWorkflow={isNewSpaceAppWorkflow}
                            setIsNewSpaceAppWorkflow={setIsNewSpaceAppWorkflow}
                        />
                    </React.Suspense>
                    :
                    <PlaceholderHeader />
                    :
                    <></>
                }
                
                <Routes>

                    <Route path="/story/:storyID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="story"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/group/:storyID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="group"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/invite/:inviteCode"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <InviteCode />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/features"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <Home profile={profile} />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/list-of-links"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <Home profile={profile} />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/challenges/adventure-lists"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <AdventureLists />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/examples"
                        element={<Navigate to="/collections" replace={true} />}
                    />

                    <Route path="/"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="home"
                                        setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                        usersFollowing={usersFollowing}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/notifications"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="notifications"
                                        setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    
                    <Route path="/gallery" element={<Navigate to={redirectForAIDisclaimer} replace />} />

                    <Route path="/search" element={<Navigate to={"/search/all"} replace />} />

                    <Route path="/search/all"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="search_posts"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/search/all/:searchSlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="search_posts"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/search/:searchSlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="search_posts"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/discover"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="discover"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/collections" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/collections/tags/:tagID" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/collections/tags/starting-with-letter/:letter" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/apps" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/apps/category/:appTagID" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/review" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/subscription" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/app/:appName/:appID" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/app/:appName/:appID" element={<Navigate to={redirectForAIDisclaimer} replace />} />


                    <Route path="/blog"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="blog"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/templates"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="space_templates"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/templates/search/:searchSlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="space_templates"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/blog/:slug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ArticleWidget 
                                        setSelectedItem={setSelectedItem} 
                                        profile={profile} 
                                    /> 
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/blog/:slug/:blogID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="blogpost"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/blog/ai/:industryID" element={<Navigate to={redirectForAIDisclaimer} replace />} />
                    <Route path="/blog/ai/:industryID/:slug/:blogID" element={<Navigate to={redirectForAIDisclaimer} replace />} />

                    <Route path="/blog/spaces/:industryID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="blogpost_categories"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/blog/spaces/:industryID/:slug/:blogID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="blogpost_categories"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

        
                    <Route path="/tutorials" element={<Navigate to={redirectForAIDisclaimer} replace />} />

                    <Route path="/tutorials/:tutorialID" element={<Navigate to={redirectForAIDisclaimer} replace />} />

                    <Route path="/developer/" element={<Navigate to={redirectForAIDisclaimer} replace />} />

                    <Route path="/developer/:appID" element={<Navigate to={redirectForAIDisclaimer} replace />} />

                    <Route path="/help"
                        element={<Navigate to="/help/introduction-to-hero" replace={true} />}
                    />
                    
                    <Route path="/help/:slug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <DocsArticle />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/news"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <NewsFeed />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/news/topic/:topic"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <NewsFeed />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/podcasts"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ArticleFeed />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/podcasts/:slug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ArticleWidget 
                                        setSelectedItem={setSelectedItem} 
                                        profile={profile} 
                                    /> 
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/ai-prompts/"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="ai_prompt_feed"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/ai-prompts/:slug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <StoreFront 
                                        setProfile={setProfile} 
                                        pings={pings}
                                        profile={profile} 
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        spaces={spaces}
                                        station={station}
                                        baseSlug="ai_prompt_slug"
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/news/:slug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <NewsArticle 
                                        setSelectedItem={setSelectedItem} 
                                        profile={profile} 
                                    /> 
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/the-hero-pages-news"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <NewsAbout />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/share-a-list-of-links"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ShareAListOfLinks />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/share-multiple-links"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ShareMultipleLinks />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/share-multiple-links-on-slack"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ShareMultipleLinksOnSlack />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/link-in-bio-on-instagram"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <LinkInBioInstagram />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/save-and-share-links-chrome-extension"
                        element={<Navigate to="/share-multiple-links" replace={true} />}
                    />

                    <Route path="/link-in-bio-on-twitter"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <LinkInBioTwitter />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/what-is-a-hero-page"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <WhatIsAHeroPage />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/link-in-bio-on-tiktok"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <LinkInBioTikTok />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/dashboard/:page"
                        element={
                            profile ?
                            <Navigate to={`/${profile.username}`} replace={true} />  :
                            <Self profile={profile} />
                        }
                    />

                    <Route path="/dashboard/edit/:spaceID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    profile ?
                                    <SpaceEditor spaces={spaces} profile={profile} station={station} /> : <CircleLoader />    
                                } 
                            </React.Suspense>
                        }
                    />

                    <Route path="/dashboard/edit/:spaceID/:pageID"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    profile ?
                                    <SpaceEditor spaces={spaces} profile={profile} station={station} /> : <CircleLoader />    
                                } 
                            </React.Suspense>
                        }
                    />

                    <Route path="/edit"
                        element={<Navigate to="/dashboard/spaces" replace={true} />}
                    />

                    <Route path="/advertise"
                        element={<Navigate to="/" replace={true} />}
                    />


                    <Route path="/dashboard"
                        element={<Navigate to="/dashboard/spaces" replace={true} />}
                    />

                    <Route path="/spaces"
                        element={<Navigate to="/dashboard/spaces" replace={true} />}
                    />

                    <Route path="/saved"
                        element={<Navigate to="/dashboard/saved" replace={true} />}
                    />

                    <Route path="/messages"
                        element={<Navigate to="/dashboard/messages" replace={true} />}
                    />

                    <Route path="/settings"
                        element={<Navigate to="/dashboard/settings" replace={true} />}
                    />

                    <Route path="/friends"
                        element={<Navigate to="/dashboard/friends" replace={true} />}
                    />

                    <Route path="/create"
                        element={<Navigate to="/dashboard/create" replace={true} />}
                    />

                    <Route path="/create-account"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <CreatAccount setProfile={setProfile} />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/sign-in"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SignIn />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/auth"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <AuthSignIn />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/forgot-password"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <ForgotPassword />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/search/u/:searchUsername"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SearchUser 
                                        authProfile={profile} 
                                        spaces={spaces} 
                                        station={station} 
                                        setStation={setStation}
                                        savedSpaces={savedSpaces} 
                                        savedSpacesProfiles={savedSpacesProfiles} 
                                        friends={friends}
                                        setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                        isSearching={true}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/search/u/:searchUsername/q/:querySlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SearchUser 
                                        authProfile={profile} 
                                        spaces={spaces} 
                                        station={station} 
                                        setStation={setStation}
                                        savedSpaces={savedSpaces} 
                                        savedSpacesProfiles={savedSpacesProfiles} 
                                        friends={friends}
                                        setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                        isSearching={true}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/search/u/:searchUsername/s/:searchSpaceSlug/q/:querySlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SearchUser 
                                        authProfile={profile} 
                                        spaces={spaces} 
                                        station={station} 
                                        setStation={setStation}
                                        savedSpaces={savedSpaces} 
                                        savedSpacesProfiles={savedSpacesProfiles} 
                                        friends={friends}
                                        setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                        isSearching={true}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/search/u/:searchUsername/s/:searchSpaceSlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SearchUser 
                                        authProfile={profile} 
                                        spaces={spaces} 
                                        station={station} 
                                        setStation={setStation}
                                        savedSpaces={savedSpaces} 
                                        savedSpacesProfiles={savedSpacesProfiles} 
                                        friends={friends}
                                        setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                        isSearching={true}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/:username"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                            <SearchUser 
                                    authProfile={profile} 
                                    spaces={spaces} 
                                    station={station} 
                                    setStation={setStation}
                                    savedSpaces={savedSpaces} 
                                    savedSpacesProfiles={savedSpacesProfiles} 
                                    friends={friends}
                                    setIsCreateSpaceOpen={setIsCreateSpaceOpen}
                                    setSelectedItem={setSelectedItem}
                                />
                            </React.Suspense>
                        }
                    />

                    <Route path="/:username/:slug/i/:itemSlug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SpaceV3
                                        authProfile={profile}
                                        defaultParams={{}} 
                                        savedSpaces={savedSpaces} 
                                        hideFooter={false} 
                                        queue={queue}
                                        station={station}
                                        spaces={spaces}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/:username/:slug/:pageslug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SpaceV3
                                        authProfile={profile}
                                        defaultParams={{}} 
                                        savedSpaces={savedSpaces} 
                                        hideFooter={false} 
                                        queue={queue}
                                        station={station}
                                        spaces={spaces}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/:username/:slug"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <SpaceV3 
                                        authProfile={profile}
                                        defaultParams={{}} 
                                        savedSpaces={savedSpaces} 
                                        hideFooter={false} 
                                        queue={queue}
                                        station={station}
                                        spaces={spaces}
                                        setSelectedItem={setSelectedItem}
                                    />
                                }
                            </React.Suspense>
                        }
                    />

                    {/* Public */}

                    <Route path="/download"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <Download />
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/explore"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <Explore showFooter/>
                                }
                            </React.Suspense>
                        }
                    />

                    <Route path="/get-featured"
                        element={<p>get featured</p>}
                    />

                    <Route path="/privacy-policy"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <PrivacyPolicy />
                                }
                            </React.Suspense>
                        } 
                    />


                    <Route path="/terms-and-conditions"
                        element={
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <TermsAndConditions />
                                }
                            </React.Suspense>
                        } 
                    />

                    <Route path="/report"
                        element={<div><br /><p>&nbsp;&nbsp;Please send an email to hello@herospace.app</p></div>} 
                    />

                    <Route path="/empty"
                        element={<></>}
                    />

                    <Route path="/nothing"
                        element={
                            userLoaded && !isUserSignedIn ?
                            <React.Suspense fallback={<CircleLoader />}>
                                {
                                    <NothingPlaceholder />
                                }
                            </React.Suspense>
                            : 
                            <CircleLoader />
                        }
                    />
                </Routes>

                <React.Suspense fallback={<EmptyLoader />}>
                    <ToastContainer
                        position="top-right"
                        closeOnClick
                        rtl={false}
                        style={{zIndex: 100000000000000000}}
                    />
                </React.Suspense>
            </AppContainer>
        </SelectedItemProvider>
    );
}

function Self({profile}: {profile: ProfileType | null}): JSX.Element {
    const navigate = useNavigate();

    useEffect(() => {
        if (profile !== null && profile !== undefined) {
            navigate(`/${profile.username}`);
        }
    }, [profile]);

    return <CircleLoader />;
}

const HomeLottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    // object-fit: cover;
    // background-color: white;
  }
`;

function PlaceholderHeader() {
    // const [showLinkInBioButton, setShowLinkInBioButton] = useState(false);

    // useEffect(() => {
    //     let linkInBioTimer = setTimeout(() => {
    //         setShowLinkInBioButton(true)
    //     }, 2000);

    //     return () => {
    //         clearTimeout(linkInBioTimer);
    //     };
    // }, []);
    
    return (
        <SmallHeaderContainer className="temp-container">
            <LogoCloud noGrow dynamic aCenter>
                <HSpacer width={5} />

                <Link to={`/`} style={{ textDecoration: 'none' }}> 
                    <Logo />
                </Link> 

                <HSpacer width={2} />
            </LogoCloud>

            <ProfileImagePlaceholder className="pulsate-profile" aria-label="Default Profile Button to Sign in to Hero">
                
            </ProfileImagePlaceholder>
        </SmallHeaderContainer>
    )
}

const ProfileImagePlaceholder = styled.div`
    object-fit: cover;
    width: ${30}px;
    height: ${30}px;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.2);
    margin: 5px;
`;

const LogoCloud = styled(HStack)`
  background: white;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 4px;
`;

const SmallHeaderContainer = styled.div `
  display: flex;
  justify-content: space-between; 
  align-items: center;
  flex-direction: row;
  padding: 5px 0px;
  padding-right: 10px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10000000000;
  transition: display 0.3s ease;
`;

const LogoContainer = styled(HStack) `
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Logo() {
    return (
        <LogoContainer noGrow dynamic className="temp-container-inside">
            <img width={33} height={21} title="Hero Avatar Logo" src={HeroMaskLogo} loading="lazy" alt="Hero Avatar Logo" />
            <HSpacer width={10} />
            <LogoHeader>hero</LogoHeader>
        </LogoContainer>
    )
}

const LogoHeader = styled.span`
  text-decoration: none;
  color: #000;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 600;

  &:hover {
    color: ${Colors.primaryPink};
  }
`;


const HeaderNavLinkFont = styled(Link)`
  text-decoration: none;
  color: ${Colors.primaryDark};
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: ${Colors.primaryPink};
  }
`;

function HeaderNavLink({title, to}: {
    title: string,
    to: string
}): JSX.Element {
    return (
        <HeaderNavLinkFont to={to} >
            {title}
        </HeaderNavLinkFont>
    )
}

const HideOnMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1064px) {
        display: none;
    }
`;

const ShowOnMobile = styled.div`
    @media screen and (min-width: 1064px) {
        display: none;
    }
`;

const IconButtonWrapper = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-right: 15px !important;
    transition: transform 0.5s;

    &:hover {
      transform: scale(0.95);
    }
`;


function CircleLoader() {
    return (
      <HomeLottieContainer>
          <div style={{width: 97, top: 100, position: 'relative'}}>
            <Loader style={{backgroundColor: "white"}}/>
          </div>
      </HomeLottieContainer>
  
    )
}

function EmptyLoader() {
    return (
     <></>
    )
}

export default App;
